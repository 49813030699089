import {
  Flex,
  Heading,
  Image,
  Text,
  AspectRatio,
  Button,
  Box,
  useBreakpoint,
} from '@chakra-ui/react'
import { Link } from '@components/Link'
import type { ReactNode } from 'react'
import { ContentfulFluid } from 'types/graphql-types'

interface SideBySideProps {
  title?: string
  subtitle?: string
  tag?: string
  img?: ContentfulFluid
  ctaCopy?: ReactNode
  buttonCopy?: string
  buttonHref?: string
  reverse?: boolean
  linkOnly?: boolean
}

export const SideBySide = ({
  reverse = false,
  linkOnly = false,
  tag,
  title,
  subtitle,
  ctaCopy,
  buttonCopy,
  buttonHref,
  img,
}: SideBySideProps) => {
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'

  let direction: 'row' | 'row-reverse' | 'column' = reverse
    ? 'row-reverse'
    : 'row'

  if (isMobile) {
    direction = 'column'
  }

  return (
    <Box bgColor="#F4F2EA">
      <Flex
        direction={direction}
        maxW={bp === '2xl' ? '1280px' : 'full'}
        mx="auto"
      >
        <AspectRatio flexShrink={0} w={isMobile ? 'full' : '50%'} ratio={1}>
          <Image srcSet={img?.srcSet} />
        </AspectRatio>
        <Flex
          direction="column"
          alignItems={isMobile ? 'center' : 'flex-start'}
          justifyContent="center"
          px={12}
          py={isMobile ? 12 : 8}
        >
          {tag && (
            <Box
              textTransform="uppercase"
              backgroundColor="white"
              color="#002FA4"
              p={2}
              fontSize="sm"
              lineHeight="1em"
              fontWeight={500}
              borderRadius={5}
              mb={4}
              cursor="default"
            >
              {tag}
            </Box>
          )}
          {title && (
            <Heading variant="sans-serif" fontWeight={400} fontSize="2xl" m={0}>
              {title}
            </Heading>
          )}
          {subtitle && <Text m={0}>{subtitle}</Text>}
          {ctaCopy && (
            <Box mt={8} textAlign={isMobile ? 'center' : 'left'}>
              {ctaCopy}
            </Box>
          )}
          {!linkOnly && buttonHref && (
            <Button
              as={Link}
              to={buttonHref || ''}
              variant="blue"
              mt={8}
              size="stout"
            >
              {buttonCopy}
            </Button>
          )}
          {linkOnly && buttonHref && (
            <Link
              mt={8}
              to={buttonHref}
              textDecoration="underline"
              fontWeight={500}
            >
              {buttonCopy}
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}
