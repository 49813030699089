import { useContentfulPreview } from '@hooks/useContentfulPreview'
import { useLocation, useNavigate } from '@reach/router'

const PreviewPage = () => {
  const navigate = useNavigate()
  const { search, hostname } = useLocation()

  if (hostname === 'reesesbookclub.com') {
    navigate('/', { replace: true })
  }

  const urlSearchParams = new URLSearchParams(search)
  const { content_type, id, env } = Object.fromEntries(
    urlSearchParams.entries()
  )

  const Page = useContentfulPreview({ contentType: content_type, env, id })

  return Page
}

export default PreviewPage
