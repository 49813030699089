import { transform } from 'lodash'

const formatImage = (value: any) => {
  const img = {
    fluid: {
      srcSet: value.url,
      aspectRatio: value.width / value.height,
    },
  }
  return img
}

const handleTransform = (result: any, value: any, key: string) => {
  if (typeof value === 'string' || value === null) {
    result[key] = value
    return
  }

  if (value.id) {
    result.id = value.id
  }

  if (value.items && Array.isArray(value.items)) {
    const newArray = []
    for (const i in value.items) {
      if (value.items[i].__typename === 'Asset') {
        newArray.push(formatImage(value.items[i]))
        continue
      }
      const subTransform = transform(value.items[i], handleTransform, {})
      newArray.push(subTransform)
    }

    result[key.replace('Collection', '')] = newArray
    return
  }

  if (value.json) {
    value.raw = JSON.stringify(value.json)
    result[key] = value
    return
  }

  if (value.__typename === 'Asset') {
    result[key] = formatImage(value)

    return
  }

  result[key] = transform(value, handleTransform, {})
}

export const contentful2Gatsby = (data: any) => {
  return transform(data, handleTransform, {})
}
