import {
  Image,
  Heading,
  Box,
  Flex,
  AspectRatio,
  Link,
  Text,
  Button,
  useBreakpoint,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import { Hero } from '@components/Hero'
import { Card } from '@components/Card'
import { SectionContainer } from '@components/SectionContainer'
import { SliderSectionContainer } from '@components/SliderSectionContainer'
import { AppleStore, GooglePlayStore } from '@components/Icon'
import { FloatingTripleImage } from '@components/FloatingTripleImage'
import { AppFeatures } from '@components/AppFeatures'
import { RichTextRaw } from '@components/RichTextRaw'
import type { ContentfulAsset, ContentfulRbcComHome } from 'types/graphql-types'

interface IndexPageProps {
  data: {
    contentfulRbcComHome: ContentfulRbcComHome
  }
}

const IndexPage = ({ data }: IndexPageProps) => {
  const {
    joinTheClubText,
    joinTheClubImages,
    joinTheClubButtonText,
    joinTheClubButtonUrl,
    hero,
    hero2,
    whatsNew,
    getTheAppImage,
    getTheApp,
    collabs,
  } = data.contentfulRbcComHome
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'
  const isTablet = bp === 'md' || bp === 'lg'
  const isDesktop = bp === 'xl' || bp === '2xl'

  return (
    <>
      {hero && (
        <SectionContainer bgGradient="yellow">
          <Hero
            img={hero?.image || ({} as ContentfulAsset)}
            smallTitle={hero?.smallTitle || ''}
            largeTitle={hero?.largeTitle || ''}
            ctaCopy={<RichTextRaw raw={hero?.bodyText?.raw || ''} /> || ''}
            ctaText={hero?.ctaButtonText || ''}
            ctaUrl={hero?.ctaButtonUrl || ''}
            ctaCenter={false}
          />
        </SectionContainer>
      )}
      {whatsNew && (
        <SliderSectionContainer
          title="What's New"
          bgColor="#FCFBF8"
          cardRatio="square"
          cardSize="lg"
          cardVariant="vertical"
        >
          {whatsNew?.map((w, i) => (
            <Card data={w} key={w?.id} />
          ))}
        </SliderSectionContainer>
      )}
      {hero2 && (
        <SectionContainer bgColor="#006950">
          <Hero
            img={hero2?.image || ({} as ContentfulAsset)}
            smallTitle={hero2?.smallTitle || ''}
            largeTitle={hero2?.largeTitle || ''}
            ctaCopy={<RichTextRaw raw={hero2?.bodyText?.raw || ''} /> || ''}
            ctaText={hero2?.ctaButtonText || ''}
            ctaUrl={hero2?.ctaButtonUrl || ''}
            textColor="white"
            size="lg"
            variant="longText"
          />
        </SectionContainer>
      )}
      <SectionContainer bgColor="#FCFBF8">
        <Flex
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent="center"
        >
          <Box maxW={isMobile ? 'full' : '50%'} mb={isMobile ? 12 : 0}>
            <FloatingTripleImage
              images={(joinTheClubImages?.images as ContentfulAsset[]) || []}
            />
          </Box>
          <Flex
            alignItems={isMobile ? 'center' : 'flex-start'}
            textAlign={isMobile ? 'center' : 'left'}
            flexDir="column"
            flexGrow={1}
            pl={isMobile ? 0 : '16'}
          >
            <Heading
              variant="sans-serif"
              fontWeight="normal"
              fontSize="3xl"
              mb={4}
              lineHeight="1em"
            >
              Join the Club
            </Heading>
            <Text mb={8} w="xs" lineHeight="tall">
              {joinTheClubText}
            </Text>
            <Button
              as={Link}
              href={joinTheClubButtonUrl}
              variant="blue"
              size="stout"
              maxW="240px"
            >
              {joinTheClubButtonText}
            </Button>
          </Flex>
        </Flex>
      </SectionContainer>
      {collabs && (
        <SliderSectionContainer
          title="Collabs"
          bgColor="#F4F2EA"
          cardRatio="tall"
          cardVariant="vertical"
          cardSize="md"
        >
          {collabs?.map(c => (
            <Card data={c} key={c?.id} />
          ))}
        </SliderSectionContainer>
      )}
    </>
  )
}

export const query = graphql`
  query IndexQuery {
    contentfulRbcComHome(pageSlug: { eq: "home" }) {
      pageTitle
      pageSlug
      hero {
        smallTitle
        largeTitle
        bodyText {
          raw
        }
        image {
          description
          fluid {
            aspectRatio
            srcSet
          }
        }
        ctaButtonUrl
        ctaButtonText
      }
      whatsNew {
        ... on ContentfulArticle {
          id
          title
          coverPhoto {
            fluid {
              aspectRatio
              srcSet
            }
          }
          coverPhotoSquare {
            fluid {
              aspectRatio
              srcSet
            }
          }
          productTag
          subtitle {
            subtitle
          }
          externalUrl {
            label
            name
            url {
              url
            }
          }
        }
        __typename
      }
      getTheAppImage {
        fluid {
          aspectRatio
          srcSet
        }
      }
      getTheApp {
        id
        title
        copy {
          raw
        }
        icon {
          fluid {
            srcSet
            aspectRatio
          }
        }
      }
      hero2 {
        smallTitle
        bodyText {
          raw
        }
        largeTitle
        image {
          description
          fluid {
            srcSet
            aspectRatio
          }
        }
        ctaButtonUrl
        ctaButtonText
      }
      joinTheClubText
      joinTheClubImages {
        images {
          fluid {
            srcSet
            aspectRatio
          }
        }
      }
      joinTheClubButtonUrl
      joinTheClubButtonText
      collabs {
        ... on ContentfulArticle {
          id
          title
          coverPhoto {
            fluid {
              aspectRatio
              srcSet
            }
          }
          coverPhotoSquare {
            fluid {
              aspectRatio
              srcSet
            }
          }
          subtitle {
            subtitle
          }
          productTag
          externalUrl {
            label
            name
            url {
              url
            }
          }
        }
        __typename
      }
    }
  }
`

export default IndexPage
