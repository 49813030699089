import {
  articleQuery,
  homeQuery,
  landingQuery,
  shopQuery,
} from '../gql/preview'
import { GraphQLClient } from 'graphql-request'
import { contentful2Gatsby } from '@lib/preview'
import ArticlePage from '../pages/article/{ContentfulArticle.title}'
import LandingPage from '@pages/landing/{ContentfulRbcComLanding.title}'
import IndexPage from '../pages/index'
import ShopPage from '../pages/shop'
import {
  ContentfulArticle,
  ContentfulRbcComHome,
  ContentfulRbcComShop,
  ContentfulRbcComLanding,
} from 'types/graphql-types'

export class PreviewClient {
  client: GraphQLClient

  constructor(url: string) {
    this.client = new GraphQLClient(url, {
      headers: {
        authorization: `Bearer ${process.env.GATSBY_APP_CONTENTFUL_ACCESS_TOKEN}`,
      },
    })

    this.article = this.article.bind(this)
    this.landing = this.landing.bind(this)
  }

  async article(id: string): Promise<JSX.Element> {
    const data = await this.client.request(articleQuery, { id })

    const articleData = {
      contentfulArticle: contentful2Gatsby(data.article) as ContentfulArticle,
    }

    return <ArticlePage data={articleData || {}} />
  }

  async landing(id: string): Promise<JSX.Element> {
    const data = await this.client.request(landingQuery, { id })

    const landingData = {
      contentfulRbcComLanding: contentful2Gatsby(
        data.article
      ) as ContentfulRbcComLanding,
    }

    return <LandingPage data={landingData || {}} />
  }

  async home(): Promise<JSX.Element> {
    const data = await this.client.request(homeQuery)

    const homeData = {
      contentfulRbcComHome: contentful2Gatsby(
        data.rbcComHomeCollection.items[0]
      ) as ContentfulRbcComHome,
    }

    return <IndexPage data={homeData || {}} />
  }

  async shop(): Promise<JSX.Element> {
    const data = await this.client.request(shopQuery)

    const shopData = {
      contentfulRbcComShop: contentful2Gatsby(
        data.rbcComShopCollection.items[0]
      ) as ContentfulRbcComShop,
    }

    return <ShopPage data={shopData || {}} />
  }
}
