import { ReactNode, useState } from 'react'
import {
  Image,
  AspectRatio,
  Box,
  Flex,
  Text,
  SimpleGrid,
  useBreakpointValue,
} from '@chakra-ui/react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ContentfulRbcComTab, Maybe } from 'types/graphql-types'

interface TabProps {
  text: string
  active: boolean
  onClick: () => void
  image: JSX.Element
}

interface TabContentProps {
  children: ReactNode
  activeTab: boolean
}

const TabContent = ({ children, activeTab = false }: TabContentProps) => (
  <Box
    as="span"
    opacity={activeTab ? 1 : 0}
    transition="opacity 150ms ease-out"
    position="absolute"
    top={0}
    left={0}
    textAlign="left"
    sx={{
      '& p': {
        lineHeight: '28px',
      },
    }}
  >
    {children}
  </Box>
)

const Tab = ({ text = '', active = false, onClick, image }: TabProps) => (
  <Flex
    direction="column"
    alignItems="center"
    position="relative"
    pb={4}
    _after={{
      content: '""',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 0,
      width: active ? '50%' : 0,
      borderBottomColor: 'black',
      borderBottomWidth: '2px',
      borderBottomStyle: 'solid',
      transition: 'width 150ms ease-out',
    }}
    _hover={{
      _after: {
        width: active ? '50%' : '8',
        transition: 'width 150ms ease-out',
      },
    }}
    cursor="pointer"
    onClick={onClick}
  >
    <AspectRatio
      w="16"
      h="16"
      ratio={1}
      borderRadius="100%"
      overflow="hidden"
      mb={4}
    >
      {image}
    </AspectRatio>
    <Text fontSize={18} lineHeight="1em" cursor="pointer" textAlign="center">
      {text}
    </Text>
  </Flex>
)

interface AppFeaturesProps {
  features: Maybe<ContentfulRbcComTab>[]
  removeBottomPadding?: boolean
}

export const AppFeatures = ({
  features,
  removeBottomPadding,
}: AppFeaturesProps) => {
  const contentHeight = useBreakpointValue({
    base: 28,
    sm: 48,
    md: 28,
  })

  const [activeTab, setActiveTab] = useState(features[0]?.title)

  return (
    <Box pt={12} pb={removeBottomPadding ? 0 : 12} maxW="container.sm">
      <SimpleGrid gridTemplateColumns="1fr 1fr 1fr" pb={12} gap={16}>
        {features?.map(f => (
          <Tab
            text={f?.title || ''}
            active={activeTab === f?.title}
            onClick={() => setActiveTab(f?.title || '')}
            image={<Image srcSet={f?.icon?.fluid?.srcSet || ''} />}
            key={`tab-${f?.id}`}
          />
        ))}
      </SimpleGrid>
      <Box h={contentHeight} position="relative" overflow="hidden">
        {features?.map(f => (
          <TabContent
            activeTab={activeTab === f?.title}
            key={`tab-content-${f?.id}`}
          >
            {documentToReactComponents(JSON.parse(f?.copy?.raw || ''))}
          </TabContent>
        ))}
      </Box>
    </Box>
  )
}
