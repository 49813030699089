import { graphql } from 'gatsby'
import { Box, Text, Flex, Container, Heading } from '@chakra-ui/react'
import { format } from 'date-fns'
import { SectionContainer } from '@components/SectionContainer'
import { RichText } from '@components/RichText'
import { Tag } from '@components/Tag'
import { ContentfulArticle } from 'types/graphql-types'

export interface ArticlePageProps {
  data: {
    contentfulArticle?: ContentfulArticle
  }
}

const Separators = () => (
  <>
    <Box
      as="hr"
      borderColor="rgb(255, 243, 75)"
      borderWidth={5}
      mb={4}
      mt={8}
      w="full"
    />
    <Box
      as="hr"
      borderColor="rgb(255, 243, 75)"
      borderWidth={5}
      mb={4}
      w="full"
    />
    <Box as="hr" borderColor="rgb(255, 243, 75)" borderWidth={5} w="full" />
  </>
)

const ArticlePage = ({ data }: ArticlePageProps) => {
  const { title, subtitle, date, body, relatedTag } =
    data.contentfulArticle || {}

  return (
    <SectionContainer bgColor="rgb(252, 251, 248)">
      <Flex direction="column" alignItems="center" mb={24}>
        <Box mb={relatedTag ? 10 : 0}>
          <Tag>{relatedTag}</Tag>
        </Box>
        <Heading
          lineHeight="1.05em"
          fontSize={60}
          maxW="container.md"
          textAlign="center"
          mb={2}
        >
          {title}
        </Heading>
        <Text mb={2} textAlign="center" fontWeight={500}>
          {subtitle?.subtitle || subtitle}
        </Text>
        <Text m={0} textTransform="uppercase" fontSize={14}>
          {date && format(new Date(date), 'MMM d, yyyy')}
        </Text>
        <Separators />
        <Container mt={12} maxW="container.md">
          {body?.raw && <RichText body={body}></RichText>}
        </Container>
      </Flex>
    </SectionContainer>
  )
}

export const query = graphql`
  query ArticlePage($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      id
      title
      relatedTag
      body {
        references {
          ... on ContentfulVideo {
            contentful_id
            __typename
            video {
              file {
                url
                contentType
              }
            }
            thumbnail {
              file {
                url
              }
            }
          }
          ... on ContentfulAsset {
            contentful_id
            __typename
            fixed(width: 1600) {
              width
              height
              src
              srcSet
            }
          }
        }
        raw
      }
      date
      subtitle {
        subtitle
      }
      brandedTag
    }
  }
`

export default ArticlePage
