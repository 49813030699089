import { gql } from 'graphql-request'

export const homeQuery = gql`
  query home {
    rbcComHomeCollection(where: { pageSlug: "home" }, limit: 1, preview: true) {
      items {
        pageTitle
        hero {
          __typename
          ...allHero
        }
        whatsNewCollection(limit: 8) {
          items {
            __typename
            ...allArticle
            ...allBook
          }
        }
        getTheAppImage {
          __typename
          url
          width
          height
        }
        getTheAppCollection(limit: 3) {
          ...getTheApp
        }
        hero2 {
          ...allHero
        }
        joinTheClubText
        joinTheClubButtonUrl
        joinTheClubButtonText
        joinTheClubImages {
          imagesCollection(limit: 3) {
            items {
              __typename
              url
              width
              height
            }
          }
        }
        collabsCollection(limit: 8) {
          items {
            __typename
            ...allArticle
            ...allBook
          }
        }
      }
    }
  }

  fragment allArticle on Article {
    __typename
    sys {
      id
    }
    title
    coverPhoto {
      __typename
      url
      width
      height
    }
    date
    subtitle
    brandedTag
  }

  fragment allBook on Book {
    __typename
    sys {
      id
    }
    title
    author
    description
    season
    aboutTheAuthor
    coverImage {
      __typename
      url
      width
      height
    }
  }

  fragment allHero on RbcComHero {
    smallTitle
    largeTitle
    bodyText {
      __typename
      json
    }
    imageCollection(limit: 2) {
      items {
        __typename
        url
        width
        height
      }
    }
    ctaButtonUrl
    ctaButtonText
  }

  fragment getTheApp on RbcComHomeGetTheAppCollection {
    items {
      sys {
        id
      }
      title
      icon {
        __typename
        url
        width
        height
      }
      copy {
        __typename
        json
      }
    }
  }
`

export const shopQuery = gql`
  query shop {
    rbcComShopCollection(where: { pageSlug: "shop" }, limit: 1, preview: true) {
      items {
        pageTitle
        hero {
          ...allHero
        }
        threeUpContentSectionCollection(limit: 3) {
          items {
            ...allBook
            ...allArticle
          }
        }
        scrollableContentSectionCollection(limit: 12) {
          items {
            ...allBook
            ...allArticle
          }
        }
        getTheAppHeading
        getTheAppSubtext
        getTheAppImage {
          __typename
          url
          width
          height
        }
        getTheAppCollection(limit: 3) {
          ...getTheApp
        }
        howItWorksButtonText
        howItWorksButtonUrl
        sideBySide2 {
          ...allSideBySide
        }
      }
    }
  }

  fragment allSideBySide on RbcComSideBySide {
    sys {
      id
    }
    title
    subtitle
    ctaText {
      __typename
      json
    }
    tag
    image {
      sys {
        id
      }
      __typename
      url
      width
      height
    }
    imageOnRight
    buttonText
    buttonUrl
  }

  fragment allArticle on Article {
    __typename
    sys {
      id
    }
    title
    coverPhoto {
      sys {
        id
      }
      __typename
      url
      width
      height
    }
    date
    subtitle
    brandedTag
  }

  fragment allBook on Book {
    __typename
    sys {
      id
    }
    title
    author
    description
    season
    aboutTheAuthor
    coverImage {
      sys {
        id
      }
      __typename
      url
      width
      height
    }
  }

  fragment allHero on RbcComHero {
    __typename
    sys {
      id
    }
    smallTitle
    largeTitle
    bodyText {
      __typename
      json
    }
    imageCollection(limit: 1) {
      items {
        sys {
          id
        }
        __typename
        url
        width
        height
      }
    }
    ctaButtonUrl
    ctaButtonText
  }
`

export const articleQuery = gql`
  query getArticle($id: String!) {
    article(id: $id, preview: true) {
      title
      subtitle
      brandedTag
      date
      body {
        json
      }
    }
  }
`

export const landingQuery = gql`
  query getLanding($id: String!) {
    rbcComLanding(id: $id, preview: true) {
      id
      title
      list
      topImage {
        fixed(width: 1600) {
          width
          height
          src
          srcSet
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
      thankYouText {
        childMarkdownRemark {
          html
        }
      }
      formTitle
      submitButtonTitle
    }
  }
`
