import { FormEventHandler, useEffect, useState } from 'react'
import { Box, Button, Flex, Input } from '@chakra-ui/react'

interface NewsletterFormLandingProps {
  submitButtonTitle?: string | null
  title?: string | null
  thankYouText?: any
  list?: string | null
}

export const NewsletterFormLanding = ({
  submitButtonTitle,
  title,
  thankYouText,
}: NewsletterFormLandingProps) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [success, setSuccess] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const thankYou = thankYouText?.childMarkdownRemark?.html || ''

  useEffect(() => {
    if (submitted) {
      const fullName = name.split(' ')
      const first_name = fullName[0] || ''
      const last_name = fullName[1] || ''
      fetch('https://a.klaviyo.com/client/subscriptions/?company_id=WpAzez', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          revision: '2023-10-15',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              custom_source: `Landing Page ${title}`,
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                  },
                },
              },
            },
            relationships: {
              list: {
                data: {
                  type: 'list',
                  id: 'WBe66M',
                },
              },
            },
          },
        }),
      })
        .then(response => {
          if (response.ok) {
            setSuccess(true)
          }
        })
        .catch(err => {
          console.error(err)
          setSuccess(false)
        })
    }
  }, [setSuccess, submitted])

  const handleSubmit: FormEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
    if (!email) return
    setSubmitted(true)
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit}
      direction="column"
      alignItems="center"
      mb={12}
      mt={4}
      width={['70%', '20%']}
      maxW={['70%', '20%']}
    >
      {!success ? (
        <>
          <Input
            type="name"
            value={name}
            onChange={e => setName(e.target.value)}
            placeholder="Enter name"
            bgColor="white"
            borderRadius={90}
            border="none"
            lineHeight="1em"
            pl={8}
            py={6}
            mb={5}
            disabled={success}
          />
          <Input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter email address"
            bgColor="white"
            borderRadius={90}
            border="none"
            lineHeight="1em"
            pl={8}
            py={6}
            mb={8}
            disabled={success}
          />
          <Button
            type="submit"
            borderRadius={90}
            bgColor="#fef24c"
            px={8}
            py={6}
            lineHeight="1em"
            disabled={success}
            width="80%"
          >
            {submitButtonTitle}
          </Button>
        </>
      ) : (
        <Flex>
          <Box
            dangerouslySetInnerHTML={{ __html: thankYou }}
            overflow="hidden"
          />
        </Flex>
      )}
    </Flex>
  )
}
