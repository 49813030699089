import { useEffect, useState } from 'react'
import { PreviewClient } from '@lib/PreviewClient'

interface UseContentfulPreviewProps {
  contentType: string
  env: string
  id: string
}

export const useContentfulPreview = ({
  contentType,
  env,
  id,
}: UseContentfulPreviewProps) => {
  const [pageComponent, setPageComponent] = useState<JSX.Element>(<div />)

  useEffect(() => {
    const graphqlUrl = `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_APP_CONTENTFUL_SPACE_ID}/environments/${env}`

    const client = new PreviewClient(graphqlUrl)

    switch (contentType) {
      case 'article':
        client.article(id).then(component => setPageComponent(component))
        break
      case 'rbcComHome':
        client.home().then(component => setPageComponent(component))
        break
      case 'rbcComShop':
        client.shop().then(component => setPageComponent(component))
        break
    }
  }, [])

  return pageComponent
}
