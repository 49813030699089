import { Image, Box, useBreakpoint } from '@chakra-ui/react'
import { ContentfulAsset } from 'types/graphql-types'

export const FloatingTripleImage = ({
  images,
}: {
  images: ContentfulAsset[]
}) => {
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'

  const image1 = images[0]
  const image2 = images[1]
  const image3 = images[2]

  return (
    <Box
      flexGrow={1}
      w={isMobile ? 'full' : '2xl'}
      h={isMobile ? 'auto' : 'xl'}
      maxW="full"
      position="relative"
      mb={isMobile ? 16 : 0}
    >
      <Image
        srcSet={image1?.fluid?.srcSet || ''}
        position={isMobile ? 'relative' : 'absolute'}
        left={isMobile ? 'auto' : 0}
        bottom={isMobile ? 'auto' : 20}
        w={isMobile ? 'full' : '2xs'}
        zIndex={isMobile ? 5 : 0}
      />

      <Image
        srcSet={image2?.fluid?.srcSet || ''}
        position={isMobile ? 'relative' : 'absolute'}
        bottom={isMobile ? 'auto' : 0}
        left={isMobile ? 'auto' : '50%'}
        transform="auto"
        translateX={isMobile ? 0 : '-50%'}
        w={isMobile ? 'full' : '2xs'}
        my={isMobile ? 12 : 0}
      />
      <Image
        srcSet={image3?.fluid?.srcSet || ''}
        position={isMobile ? 'relative' : 'absolute'}
        bottom={isMobile ? 'auto' : 48}
        right={isMobile ? 'auto' : 0}
        w={isMobile ? 'full' : '2xs'}
      />
    </Box>
  )
}
