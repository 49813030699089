/* eslint-disable no-unsafe-optional-chaining */
import { graphql } from 'gatsby'
import {
  AspectRatio,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  useBreakpoint,
} from '@chakra-ui/react'
import { Hero } from '@components/Hero'
import { Card } from '@components/Card'
import { SideBySide } from '@components/SideBySide'
import { SectionContainer } from '@components/SectionContainer'
import { SliderSectionContainer } from '@components/SliderSectionContainer'
import { RichTextRaw } from '@components/RichTextRaw'
import {
  ContentfulAsset,
  ContentfulFluid,
  ContentfulRbcComShop,
} from 'types/graphql-types'
import { AppFeatures } from '@components/AppFeatures'

interface ShopPageProps {
  data: {
    contentfulRbcComShop: ContentfulRbcComShop
  }
}

const ShopPage = ({ data }: ShopPageProps) => {
  const {
    hero,
    threeUpContentSection,
    scrollableContentSection,
    sideBySide2,
    howItWorksButtonText,
    howItWorksButtonUrl,
    getTheApp,
    getTheAppImage,
    getTheAppSubtext,
    getTheAppHeading,
  } = data?.contentfulRbcComShop
  const bp = useBreakpoint()
  const isMobile = bp === 'base' || bp === 'sm'
  const isTablet = bp === 'md' || bp === 'lg'
  const isDesktop = bp === 'xl' || bp === '2xl'
  return (
    <>
      {hero && (
        <SectionContainer bgGradient="purple">
          <Hero
            img={hero?.image || ({} as ContentfulAsset)}
            smallTitle={hero?.smallTitle || ''}
            largeTitle={hero?.largeTitle || ''}
            ctaCopy={<RichTextRaw raw={hero?.bodyText?.raw || ''} /> || ''}
            ctaText={hero?.ctaButtonText || ''}
            ctaUrl={hero?.ctaButtonUrl || ''}
            ctaAnalyticsEvent="shop_link_clicked"
          />
        </SectionContainer>
      )}
      {threeUpContentSection && threeUpContentSection?.length > 0 && (
        <SectionContainer>
          <Heading
            as="h3"
            lineHeight="1em"
            mb={8}
            fontSize="2xl"
            variant="sans-serif"
          >
            The Gift of Reading Box Sets
          </Heading>
          <SimpleGrid
            templateColumns={`repeat(${isMobile ? 1 : 3}, 1fr)`}
            gap={isMobile ? 4 : 12}
          >
            {threeUpContentSection?.map(c => (
              <Card data={c} size="full" key={c?.id} variant="vertical" />
            ))}
          </SimpleGrid>
        </SectionContainer>
      )}

      {getTheApp && getTheApp.length > 0 && (
        <Flex
          direction={isMobile ? 'column' : 'row'}
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Flex
            w={isMobile ? 'full' : '50%'}
            bgColor="#EEECE0"
            justifyContent={bp === '2xl' ? 'flex-end' : 'center'}
            alignItems="center"
          >
            <AspectRatio
              ratio={1}
              w="100%"
              // maxW="320px"
              minW="120px"
              maxH="720px"
              sx={{
                '& > img': {
                  objectFit: 'cover',
                },
              }}
            >
              <Image
                srcSet={getTheAppImage?.fluid?.srcSet || ''}
                height={'full'}
              />
            </AspectRatio>
          </Flex>
          <Flex
            direction="column"
            alignItems="flex-start"
            justifyContent="center"
            flexGrow={1}
            py={isDesktop ? '96px' : isTablet ? 16 : 8}
            pr={8}
            pl={isDesktop ? '96px' : 8}
            bgColor="#FCFBF8"
          >
            <Heading
              variant="sans-serif"
              fontWeight="normal"
              textAlign={isMobile || isTablet ? 'center' : 'left'}
              fontSize="3xl"
            >
              {getTheAppHeading || ''}
            </Heading>
            <Text m={0} textAlign={isMobile || isTablet ? 'center' : 'left'}>
              {getTheAppSubtext || ''}
            </Text>
            <AppFeatures
              features={getTheApp || []}
              removeBottomPadding={true}
            />
            <Flex
              maxW={isMobile ? 'full' : 'xs'}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                as={Link}
                href={howItWorksButtonUrl}
                variant="blue"
                size="stout"
              >
                {howItWorksButtonText}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      {scrollableContentSection && scrollableContentSection?.length > 0 && (
        <SliderSectionContainer
          title="Do Good Goods"
          bgColor="#FCFBF8"
          cardRatio="square"
          cardSize="lg"
          cardVariant="vertical"
          fontWeight="bold"
        >
          {scrollableContentSection?.map(c => (
            <Card data={c} key={c?.id} />
          ))}
        </SliderSectionContainer>
      )}

      {sideBySide2 &&
        sideBySide2.length &&
        sideBySide2.map((panel: any) => (
          <SideBySide
            key={`side-by-side--${panel.id}--${panel.title}`}
            title={panel.title || ''}
            subtitle={panel.subtitle || ''}
            ctaCopy={<RichTextRaw raw={panel.ctaText?.raw || ''} />}
            img={panel.image?.fluid || ({} as ContentfulFluid)}
            buttonCopy={panel.buttonText || ''}
            buttonHref={panel.buttonUrl || ''}
            reverse={panel.imageOnRight || false}
            tag={panel.tag || ''}
            linkOnly={panel.linkOnly || false}
          />
        ))}
    </>
  )
}

export const query = graphql`
  query ShopQuery {
    contentfulRbcComShop(pageSlug: { eq: "shop" }) {
      hero {
        smallTitle
        largeTitle
        image {
          description
          fluid {
            srcSet
            aspectRatio
          }
        }
        bodyText {
          raw
        }
        ctaButtonUrl
        ctaButtonText
      }
      threeUpContentSection {
        __typename
        ... on Node {
          ... on ContentfulArticle {
            id
            title
            coverPhoto {
              fluid {
                aspectRatio
                srcSet
              }
            }
            subtitle {
              subtitle
            }
            productTag
            externalUrl {
              label
              name
              url {
                url
              }
            }
          }
          ... on ContentfulBook {
            id
            bookTitle: title {
              title
            }
            coverImage {
              fluid {
                aspectRatio
                srcSet
              }
            }
            subtitle {
              subtitle
            }
            productTag
            externalUrl {
              label
              name
              url {
                url
              }
            }
          }
        }
      }
      getTheAppHeading
      getTheAppSubtext
      getTheAppImage {
        fluid {
          aspectRatio
          srcSet
        }
      }
      getTheApp {
        id
        title
        copy {
          raw
        }
        icon {
          fluid {
            srcSet
            aspectRatio
          }
        }
      }
      scrollableContentSection {
        __typename
        ... on Node {
          ... on ContentfulArticle {
            id
            title
            coverPhoto {
              fluid {
                aspectRatio
                srcSet
              }
            }
            subtitle {
              subtitle
            }
            productTag
            externalUrl {
              label
              name
              url {
                url
              }
            }
          }
          ... on ContentfulBook {
            id
            bookTitle: title {
              title
            }
            coverImage {
              fluid {
                aspectRatio
                srcSet
              }
            }
            subtitle {
              subtitle
            }
            productTag
            externalUrl {
              label
              name
              url {
                url
              }
            }
          }
        }
      }
      howItWorksButtonText
      howItWorksButtonUrl
      sideBySide2 {
        ... on ContentfulRbcComSideBySide {
          title
          ctaText {
            raw
          }
          tag
          imageOnRight
          buttonText
          buttonUrl
          linkOnly
          image {
            fluid {
              srcSet
              aspectRatio
            }
          }
          subtitle
        }
      }
    }
  }
`

export default ShopPage
