import { graphql } from 'gatsby'
import { ContentfulRbcComLanding } from 'types/graphql-types'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { NewsletterFormLanding } from '@components/NewsletterFormLanding'
import GiftGuideGif from '../../assets/images/RBC_GiftGuide_Gif_102221.gif'

const CenteredRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  margin-top: 63px;
  max-height: 250px;
  @media (max-width: 896px) {
    margin-top: 80px;
  }
`

const Text = styled(Box)`
  margin-top: 27px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  width: 35%;
  font-style: normal;
  font-weight: normal;
  @media (max-width: 896px) {
    width: 80%;
  }
`

const JoinText = styled.p`
  margin-top: 41px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
`

export interface LandingPageProps {
  data: {
    contentfulRbcComLanding?: ContentfulRbcComLanding
  }
}

const LandingPage = ({ data }: LandingPageProps) => {
  const {
    topImage,
    text,
    formTitle,
    submitButtonTitle,
    title,
    list,
    thankYouText,
  } = data.contentfulRbcComLanding || {}

  const topText = text?.childMarkdownRemark?.html || ''
  const image = topImage?.fixed?.src || GiftGuideGif

  return (
    <Box
      bg="linear-gradient(181.12deg, rgb(167, 169, 246) -2.97%, rgb(198, 234, 250) 92.1%)"
      pb="300px"
    >
      <CenteredRow>
        <Image src={image} alt="landing page image" />
      </CenteredRow>
      <CenteredRow>
        <Text dangerouslySetInnerHTML={{ __html: topText }} overflow="hidden" />
      </CenteredRow>
      <CenteredRow>
        <JoinText>{formTitle}</JoinText>
      </CenteredRow>
      <CenteredRow>
        <NewsletterFormLanding
          submitButtonTitle={submitButtonTitle}
          title={title}
          thankYouText={thankYouText}
          list={list}
        />
      </CenteredRow>
    </Box>
  )
}

export const query = graphql`
  query LandingPage($id: String!) {
    contentfulRbcComLanding(id: { eq: $id }) {
      id
      title
      list
      topImage {
        fixed(width: 1600) {
          width
          height
          src
          srcSet
        }
      }
      thankYouText {
        childMarkdownRemark {
          html
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
      formTitle
      submitButtonTitle
    }
  }
`

export default LandingPage
